import React from 'react';
import ImageLink from '../../../../../core/Components/ImageLink';

const PageMobileHeader = ({
  fields,
  showMobileMenu,
  toggleMobileMenu,
  menuShow,
  menuHide,
}) => {
  const { mobileLogo, logoLink, primaryNavList, showLanguageSelector } = fields;
  const displayMenu =
    (primaryNavList && primaryNavList.length > 0) || showLanguageSelector.value;
  return (
    <div className="page-mobile-header ">
      <ImageLink
        imageField={mobileLogo}
        linkField={logoLink}
        className="mobile-logo"
      />

      {displayMenu && (
        <div
          id="mobile-menu"
          className={`${showMobileMenu ? 'open' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            const cShowMiobileMenu = !showMobileMenu;
            toggleMobileMenu && toggleMobileMenu(cShowMiobileMenu);

            if (cShowMiobileMenu) {
              menuShow && menuShow();
            } else {
              menuHide && menuHide();
            }
          }}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </div>
  );
};

export default PageMobileHeader;
