import React from 'react';
import Link from '../../../../core/Components/Link';

const SecondaryNavigationItem = ({ fields, isBottom, isActive }) => {
  const vlink = fields.link;
  if (vlink.value && isBottom) {
    vlink.value.href = vlink.value.href + '#';
  }
  return vlink.value ? (
    <div className="sec-nav">
      <Link className={`nav-link ${isActive ? 'active' : ''}`} field={vlink} />
    </div>
  ) : null;
};

export default SecondaryNavigationItem;
