import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import resolveSite from "../../../../SiteResolver";

// copied and adapted from src/components/content/_rebrand/RebrandGenericHeader/RebrandGenericHeader.js
const mapBrowserLanguageToUrlLanguage = (browserLanguage) => {
  // exceptions
  if (["no", "nb", "nb-no", "nn-no"].includes(browserLanguage.toLowerCase())) {
    return "no";
  }

  if (browserLanguage.toLowerCase() === "pt-br") {
    return "pt-br";
  }

  if (["zh", "zh-cn", "zh-sg"].includes(browserLanguage.toLowerCase())) {
    return "zh-hans";
  }

  if (["zh-hk", "zh-mo", "zh-tw"].includes(browserLanguage.toLowerCase())) {
    return "zh-hant";
  }

  const languageCodes = [
    "ar",
    "az",
    "bg",
    "cs",
    "da",
    "de",
    "el",
    "es",
    "et",
    "fa",
    "fi",
    "fr",
    "he",
    "hr",
    "hu",
    "id",
    "is",
    "it",
    "ja",
    "ka",
    "kk",
    "ko",
    "lt",
    "lv",
    "mk",
    "ms",
    "nl",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "sl",
    "sq",
    "sr",
    "sv",
    "th",
    "tr",
    "uk",
    "uz",
    "vi",
  ];

  for (const languageCode of languageCodes) {
    if (browserLanguage.startsWith(languageCode)) {
      return languageCode;
    }
  }

  return "en";
};

const replaceUrlLanguage = (url, language, forceEN = false) => {
  const urlObj = new URL(url);

  urlObj.pathname = urlObj.pathname.replace(
    /\/global\/[^/]{2,}(\/|$)/,
    `/global/${language}$1`,
  );

  if (forceEN) {
    urlObj.searchParams.set("forceEN", "true");
  } else {
    urlObj.searchParams.delete("forceEN");
  }

  return urlObj.toString();
};

const replaceUrlLanguageForSitecore = (url, language) => {
  const urlObj = new URL(url);
  urlObj.searchParams.set(
    "sc_lang",
    language.fields["Regional Iso Code"].value,
  );
  return urlObj.toString();
};

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isSitecore: false,
      languageText: "English",
      location: null, // set window location after client-side render
    };
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.setState({ isDropdownOpen: false });
  }

  handleToggleDropdown(evt) {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    evt.nativeEvent.stopImmediatePropagation();
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const urlIsSitecore = params.has("sc_mode");

    if (urlIsSitecore) {
      let languageText;

      const langCode = params.get("sc_lang");
      if (langCode) {
        languageText = this.props.availableLanguages.find(
          (language) => language.fields["Regional Iso Code"].value === langCode,
        );
      }

      this.setState({
        isSitecore: true,
        location: window.location,
        ...(languageText
          ? { languageText: languageText?.fields.displayName.value }
          : {}),
      });

      return;
    }

    if (
      window.location.pathname.toLowerCase().startsWith("/global/en") &&
      params.get("forceEN") !== "true" &&
      navigator.language &&
      !navigator.language.startsWith("en")
    ) {
      window.location.replace(
        replaceUrlLanguage(
          window.location.href,
          mapBrowserLanguageToUrlLanguage(navigator.language),
        ),
      );
    } else {
      const siteObject = resolveSite(window.location.pathname);
      const urlCode = mapBrowserLanguageToUrlLanguage(siteObject.lang);

      this.setState({
        languageText: this.props.availableLanguages.find(
          (language) =>
            language.fields.cochlearLanguageCode.value.toLowerCase() ===
            urlCode,
        )?.fields.displayName.value,
        location: window.location,
      });
    }

    window.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  }

  render() {
    return (
      <div className="language-container">
        <div className="dropdown dd-language right-md">
          <button
            className="btn btn-default dropdown-toggle uk btn-no-arrow"
            onClick={this.handleToggleDropdown}
          >
            {this.state.languageText}
            <span className="caret"></span>
          </button>

          {this.state.isDropdownOpen ? (
            <ul className="countries_list dropdown-menu">
              {this.props.availableLanguages.map((language) => {
                const href = this.state.location
                  ? this.state.isSitecore
                    ? replaceUrlLanguageForSitecore(
                        this.state.location.href,
                        language.fields["Regional Iso Code"].value,
                      )
                    : replaceUrlLanguage(
                        this.state.location.href,
                        language.fields.cochlearLanguageCode.value,
                        language.fields.cochlearLanguageCode.value === "en", // forceEN
                      )
                  : "";

                return (
                  <li key={language.name}>
                    <a href={href}>
                      <span>{language.fields.displayName.value}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(LanguageSelector);
