import React from 'react';
import detectIt from 'detect-it';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SecondaryNavigationItem from './Components/SecondaryNavigationItem';
import PrimaryNavigationItem from './Components/PrimaryNavigationItem';
import LanguageSelector from './Components/LanguageSelector';
import PageMobileHeader from './Components/PageMobileHeader/PageMobileHeader';
import ImageLink from '../../../core/Components/ImageLink';
import Link from '../../../core/Components/Link';
import { PropTypes, DefaultProps } from '../../../core/Utils/SitecoreFields';
import ReactDOM from 'react-dom';

class PageNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      primaryActiveIndex: 0,
      secondaryActiveIndex: 0,
      showMobileMenu: false,
      forAndroid: false,
      foriOS: false,
    };
  }

  handleScroll = () => {
    const scrollTarget = this.headerRef;
    if (scrollTarget) {
      this.setState((prevState) => {
        //ie can't usee scrollY or getBoundingClientRect().y, so we have fallbacks
        const boundY = scrollTarget.getBoundingClientRect().y;
        const y = window.scrollY || window.pageYOffset;
        return {
          sticky: prevState.sticky
            ? y !== 0
            : boundY
            ? boundY < -150
            : window.pageYOffset > 150,
        };
      });
    }
  };

  initActiveIndexs() {
    const currentUrl = String(window.location.href).toLowerCase();
    const { primaryNavList } = this.props.fields;
    if (primaryNavList && primaryNavList.length > 0) {
      for (let p = 0; p < primaryNavList.length; p++) {
        let secondaryNavList = primaryNavList[p].fields.secondaryNavList;
        if (secondaryNavList && secondaryNavList.length > 0) {
          for (let s = 0; s < secondaryNavList.length; s++) {
            const secondaryHref = secondaryNavList[s].fields.link.value.href;
            if (currentUrl.indexOf(secondaryHref) > -1) {
              this.setState({
                secondaryActiveIndex: s,
                primaryActiveIndex: p,
                forAndroid: secondaryHref.indexOf('android') > -1,
                foriOS: secondaryHref.indexOf('android') <= -1,
              });

              break;
            }
          }
        } else {
          if (
            currentUrl.indexOf(primaryNavList[p].fields.link.value.href) > -1
          ) {
            this.setState({
              secondaryActiveIndex: 0,
              primaryActiveIndex: p,
            });
            break;
          }
        }
      }
    }
  }

  checkAndRedirect() {
    const uAgent = navigator.userAgent;
    let platform = '';
    if (uAgent.indexOf('Android') > -1 || uAgent.indexOf('Linux') > -1) {
      platform = 'Android';
    } else if (uAgent.indexOf('iPhone') > -1 || uAgent.indexOf('iPad') > -1) {
      platform = 'iOS';
    }

    const currentUrl = String(window.location.href).toLowerCase();

    const { primaryNavList } = this.props.fields;

    if (primaryNavList && primaryNavList.length > 0) {
      for (let p = 0; p < primaryNavList.length; p++) {
        let secondaryNavList = primaryNavList[p].fields.secondaryNavList;
        if (secondaryNavList && secondaryNavList.length > 0) {
          for (let s = 0; s < secondaryNavList.length; s++) {
            const secHref = String(
              secondaryNavList[s].fields.link.value.href,
            ).toLowerCase();
            if (currentUrl.endsWith(secHref)) {
              if (
                platform !== '' &&
                secondaryNavList[s].fields.link.value.text !== platform
              ) {
                window.location.href =
                  secondaryNavList[1 - s].fields.link.value.href;
              }
              break;
            }
          }
        }
      }
    }
  }

  addBottomSecondaryNavigation() {
    const { primaryActiveIndex, secondaryActiveIndex } = this.state;
    const { primaryNavList } = this.props.fields;
    if (
      typeof primaryNavList == 'undefined' ||
      primaryNavList == null ||
      primaryNavList.length == 0
    ) {
      return;
    }
    const secondaryNavList =
      primaryNavList[primaryActiveIndex].fields.secondaryNavList;
    if (!secondaryNavList || secondaryNavList.length <= 0) {
      return;
    }
    const main = document.getElementById('main');
    const footers = document.getElementsByClassName('global-footer');
    if (!footers || footers.length === 0) {
      return;
    }
    const footer = footers[0];
    const bottomSecondaryNav = document.createElement('div');
    bottomSecondaryNav.className = 'secondary-navigations-bottom';
    main.insertBefore(bottomSecondaryNav, footer);

    ReactDOM.render(
      <React.Fragment>
        <div className="l-padding">
          <div className="sec-navs-middle">
            {secondaryNavList.map((item, index) => (
              <SecondaryNavigationItem
                fields={item.fields}
                isBottom={true}
                key={`bottom-secondary-${item.id}-${index}`}
                isActive={index === secondaryActiveIndex}
              />
            ))}
          </div>
        </div>
      </React.Fragment>,
      bottomSecondaryNav,
    );
  }

  addMobileScreenNav() {
    const {
      mobileLogo,
      logoLink,
      primaryNavList,
      availableLanguages,
      showLanguageSelector,
    } = this.props.fields;
    const { primaryActiveIndex } = this.state;
    const main = document.getElementById('main');
    const mainP = main.parentElement;
    mainP.style.overflow = 'hidden';

    const mobileScreenNav = document.createElement('div');
    mobileScreenNav.className = 'aside-container';
    mobileScreenNav.id = 'aside';
    mainP.appendChild(mobileScreenNav);

    ReactDOM.render(
      <React.Fragment>
        <ImageLink
          imageField={mobileLogo}
          linkField={logoLink}
          className="aside-avatar"
        />
        {primaryNavList && primaryNavList.length > 0 && (
          <ul class="aside-navigation">
            {primaryNavList.map((item, index) => (
              <li
                key={`mobile-nav-${item.id}-${index}`}
                className={`aside-navigation-item ${
                  index === primaryActiveIndex ? 'active' : ''
                }`}
              >
                <Link
                  field={item.fields.link}
                  onClick={() => {
                    this.setState(
                      {
                        showMobileMenu: false,
                      },
                      () => this.menuHide(),
                    );
                  }}
                />
              </li>
            ))}
          </ul>
        )}
        {!!showLanguageSelector.value && availableLanguages.length > 0 ? (
          <div className="aside-languageSelector">
            <LanguageSelector availableLanguages={availableLanguages} />
          </div>
        ) : null}
      </React.Fragment>,
      mobileScreenNav,
    );
  }

  menuShow = () => {
    let asideDom = document.getElementById('aside');
    asideDom.classList.add('aside-show');
    let mainDom = document.getElementById('main');
    mainDom.classList.add('main-side-show');
  };

  menuHide = () => {
    let asideDom = document.getElementById('aside');
    asideDom.classList.remove('aside-show');
    let mainDom = document.getElementById('main');
    mainDom.classList.remove('main-side-show');
  };

  componentDidMount() {
    this.checkAndRedirect();
    window.addEventListener(
      'scroll',
      this.handleScroll,
      detectIt.passiveEvents ? { passive: true } : false,
    );
    this.initActiveIndexs();
    setTimeout(() => {
      this.addBottomSecondaryNavigation();
      this.addMobileScreenNav();
    }, 500);
  }

  componentWillUnmount() {
    window.removeEventListener(
      'scroll',
      this.handleScroll,
      detectIt.passiveEvents ? { passive: true } : false,
    );
  }

  render() {
    const { sticky, primaryActiveIndex, secondaryActiveIndex, showMobileMenu } =
      this.state;
    const {
      desktopLogo,
      logoLink,
      primaryNavList,
      availableLanguages,
      showLanguageSelector,
    } = this.props.fields;

    return (
      <React.Fragment>
        {!showMobileMenu && (
          <header
            ref={(header) => {
              this.headerRef = header;
            }}
            className={`page-navigation ${sticky ? 'is-sticky-header' : ''}`}
          >
            <div className={`primary-navigations ${sticky ? 'is-sticky' : ''}`}>
              <div className="l-padding f-jc-sb">
                <div className="logo">
                  <ImageLink imageField={desktopLogo} linkField={logoLink} />
                </div>
                {primaryNavList && primaryNavList.length > 0 && (
                  <div className="pri-navs">
                    {primaryNavList.map((item, index) => (
                      <PrimaryNavigationItem
                        fields={item.fields}
                        key={item.id}
                        index={index}
                        primaryNavList={primaryNavList}
                        forAndroid={this.state.forAndroid}
                        foriOS={this.state.foriOS}
                        isActive={index === primaryActiveIndex}
                      />
                    ))}
                  </div>
                )}
                {!!showLanguageSelector.value &&
                availableLanguages.length > 0 ? (
                  <div className="languageSelector">
                    <LanguageSelector availableLanguages={availableLanguages} />
                  </div>
                ) : null}
              </div>
            </div>
          </header>
        )}

        {primaryNavList &&
          primaryNavList.length > 0 &&
          primaryNavList[primaryActiveIndex].fields.secondaryNavList &&
          primaryNavList[primaryActiveIndex].fields.secondaryNavList.length >
            0 && (
            <div className="secondary-navigations">
              <div className="l-padding">
                <div className="sec-navs" dir="ltr">
                  {primaryNavList[
                    primaryActiveIndex
                  ].fields.secondaryNavList.map((item, index) => (
                    <SecondaryNavigationItem
                      fields={item.fields}
                      isBottom={false}
                      key={item.id}
                      isActive={index === secondaryActiveIndex}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

        <PageMobileHeader
          fields={this.props.fields}
          showMobileMenu={showMobileMenu}
          menuShow={this.menuShow}
          menuHide={this.menuHide}
          toggleMobileMenu={(v) => {
            this.setState({ showMobileMenu: v });
          }}
        />
      </React.Fragment>
    );
  }
}

PageNavigation.propTypes = PropTypes.SitecoreItem.inject({
  desktopLogo: PropTypes.Image,
  mobileLogo: PropTypes.Image,
  logoLink: PropTypes.Link,
  primaryNavList: PropTypes.Multilist.inject({
    link: PropTypes.Link,
    secondaryNavList: PropTypes.Multilist.inject({
      link: PropTypes.Link,
    }),
  }),
  availableLanguages: PropTypes.Multilist.inject({
    Iso: PropTypes.SingleLineText,
    displayName: PropTypes.SingleLineText,
  }),
});

PageNavigation.defaultProps = DefaultProps.SitecoreItem.inject({
  desktopLogo: DefaultProps.Image,
  mobileLogo: DefaultProps.Image,
  logoLink: DefaultProps.Link,
  primaryNavList: DefaultProps.Multilist,
  availableLanguages: DefaultProps.Multilist,
});

export default withSitecoreContext()(PageNavigation);
