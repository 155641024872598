import React from 'react';
import Link from '../../../../core/Components/Link';

const PrimaryNavigationItem = ({
  fields,
  primaryNavList,
  index,
  forAndroid,
  foriOS,
  isActive,
}) => {
  const vlink = fields.link;
  if (vlink.value && (forAndroid || foriOS)) {
    const secNavs = primaryNavList[index].fields.secondaryNavList;
    let androidLink = '';
    let iosLink = '';
    for (let i = 0; i < secNavs.length; i++) {
      if (secNavs[i].fields.link.value.href.indexOf('android') <= -1) {
        iosLink = secNavs[i].fields.link.value.href;
      } else {
        androidLink = secNavs[i].fields.link.value.href;
      }
    }
    if (forAndroid) {
      vlink.value.href = androidLink;
    }
    if (foriOS) {
      vlink.value.href = iosLink;
    }
  }

  return vlink.value ? (
    <div class="pri-nav">
      <Link
        className={`nav-link ${
          isActive && fields.link.value.linktype === 'internal' ? 'active' : ''
        }`}
        field={vlink}
      />
    </div>
  ) : null;
};

export default PrimaryNavigationItem;
